import PhotoAlbum from "react-photo-album";
import React, { useEffect, useState } from 'react'
import {getConversion} from "../../api/images";

const GalleryDetailsContent = ({ gallery }) => {
    const { body, categories, media, published_at, slug, title } = gallery
    const [show, setShow] = useState(false)

    var featured_image = media.filter(media => media.collection_name === 'featured_images');
    var medias = media.filter(media => media.collection_name === 'posts').map(function (media) {
        return getConversion(media);
    });
    function splitToNChunks(array, n) {
        let result = [];
        for (let i = n; i > 0; i--) {
            result.push(array.splice(0, Math.ceil(array.length / i)));
        }
        return result;
    }

    var medias = splitToNChunks(medias, 4)
    const showImg = (img) => {
        if (!show) {


            var div = document.createElement("div");
            div.id = "darkbox";
            div.innerHTML = '<img class="darkboximg" src="' + img + '" />';
            document.body.appendChild(div);
            let box = document.getElementById("darkbox");

            box.addEventListener('click', (event) => {
                let element = document.getElementById("darkbox");
                element.parentNode.removeChild(element);

                setShow(false);
            })

            setShow(true);

        } else {
            // Remove it
            let element = document.getElementById("darkbox");
            element.parentNode.removeChild(element);

            setShow(false);
        }

    };

    return (

        <section className='gallery-area pt-100 pb-70'>
            <div className='container'>
                <div className='row'>

                    {medias.map((media) => {
                        return (
                            <>
                                {media.map((item) => {
                                    return (
                                        <>
                                            <div className='col-lg-3 col-md-6 col-sm-6'>

                                                <div className="single-gallery-item">
                                                    <a onClick={() => showImg(item)}>
                                                        <img src={item} 
                                                        style={{width: '100%', height: '100%'}} />

                                                    </a>

                                                </div>
                                            </div>

                                        </>

                                    )
                                })}


                            </>

                        )
                    })}



                </div>
            </div>
        </section>
    )
}

export default GalleryDetailsContent